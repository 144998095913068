import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const generatePDF = (data) => {
  const documentDefinition = {
    content: [],
    styles: {
      header: {
        fontSize: 15,
        bold: true,
        alignment: "center",
        margin: [0, 0, 0, 10], // left, top, right
      },
      subheader: {
        // color: "#808080",
        margin: [0, 20, 0, 2], // left, top, right, bottom
        lineHeight: 0.8,
        bold: true,
      },
      keyValue: {
        margin: [0, 2, 0, 2], // left, top, right, bottom
        fontSize: 13,
      },
      keyValue1: {
        margin: [0, 2, 0, 2], // left, top, right, bottom
        color: "#848884",
        fontSize: 10,
        lineHeight: 0.8,
      },
      commentDateStyle: {
        fontSize: 10,
        bold: true,
        color: "#848884",
        margin: [0, 5, 0, 0], // left, top, right, bottom
      },
      commentTextStyle: {
        fontSize: 11,
      },
      tableHeader: {
        // color: "#808080",
        bold: true,
        margin: [0, 5, 0, 0], // left, top, right, bottom
      },
      tableCell: {
        margin: [0, 0, 0, 2], // left, top, right, bottom
        fontSize: 13,
      },
    },
  };

  // Function to format date and time
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime())
      ? dateString
      : date.toLocaleString("en-US", {
        timeZone: "UTC",
        hour12: true,
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        // hour: "numeric",
        // minute: "2-digit",
      });
  };

  // Function to format currency
  const formatCurrency = (amount) => {
    if (isNaN(amount)) return String(amount);
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  };

  // Add Title
  documentDefinition.content.push({
    text: "Cash Lot and Loan Log",
    style: "header",
  });

  // Add Subdivision and Company
  const subdivision = `${data.projectName} SEC ${data.section}`.toUpperCase();
  const company = `Highland-${data.region}`;
  documentDefinition.content.push(
    {
      stack: [
        { text: "Subdivision:", style: "keyValue1" },
        { text: subdivision, style: "keyValue" },
      ],
      style: "keyValue",
    },
    {
      stack: [
        { text: "Company:", style: "keyValue1" },
        { text: company, style: "keyValue" },
      ],
      style: "keyValue",
    }
  );

  // Add Date Ordered and Title Work if present
  if (data.createdDatetime) {
    const dateOrdered = formatDateTime(data.createdDatetime);
    documentDefinition.content.push({
      stack: [
        { text: "Date Ordered:", style: "keyValue1" },
        { text: dateOrdered, style: "keyValue" },
      ],
      style: "keyValue",
    });
  }
  if (data.modifiedDatetime) {
    const titleWork = formatDateTime(data.modifiedDatetime);
    documentDefinition.content.push({
      stack: [
        { text: "Title Work:", style: "keyValue1" },
        { text: titleWork, style: "keyValue" },
      ],
      style: "keyValue",
    });
  }

  // Add GF# if present
  if (data.guaranteeFileNumber) {
    documentDefinition.content.push({
      stack: [
        { text: "GF #:", style: "keyValue" },
        { text: data.guaranteeFileNumber, style: "keyValue" },
      ],
      style: "keyValue",
    });
  }

  // Add Takedown Lots section if present
  if (data.takedownLots && data.takedownLots.length > 0) {
    // documentDefinition.content.push({
    //   text: "Takedown Lots",
    //   style: "subheader",
    // });

    // Define table structure
    let tableBody = [
      // Table headers
      [
        { text: "Address", style: "tableHeader" },
        { text: "Proj/Job", style: "tableHeader" },
        { text: "Price", style: "tableHeader" },
        { text: "Lot/Block", style: "tableHeader" },
      ],
    ];

    // Add table rows for each lot
    data.takedownLots.forEach((lot) => {
      if (lot.isActive) {
        // make sure it works if block is empty
        const lotBlockValue = lot.lot
          ? `${lot?.lot || ""}/${lot?.block || ""}`
          : "";
        const addressValue = lot.addressStreet
          ? lot.addressStreet.toUpperCase()
          : "";
        const projJobValue = `${data.projectNumber}-${lot.jobNumber}`;
        const priceValue = lot.price ? formatCurrency(lot.price) : "";

        tableBody.push([
          { text: addressValue, style: "tableCell" },
          { text: projJobValue, style: "tableCell" },
          { text: priceValue, style: "tableCell" },
          { text: lotBlockValue, style: "tableCell" },
        ]);
      }
    });

    // Add the table to the document content
    documentDefinition.content.push({
      table: {
        headerRows: 1,
        widths: ["40%", "20%", "20%", "20%"], // Adjust widths as needed
        body: tableBody,
        style: "table",
      },
      layout: "noBorders", // Optional: adds light horizontal lines between rows for better readability
    });
  }

  // Add Comments section if present
  if (data.takedownComments && data.takedownComments.length > 0) {
    documentDefinition.content.push({ text: "Comments", style: "subheader" });
    data.takedownComments.forEach((comment) => {
      if (comment.isActive) {
        const commentDate = formatDateTime(comment.createdDatetime);
        // Use a stack to ensure the date and the comment text appear on separate lines
        documentDefinition.content.push({
          stack: [
            {
              text: `${comment.createdBy} ${commentDate}`,
              style: "commentDateStyle",
            }, // You can define a new style for comment dates if needed
            { text: comment.comment, style: "commentTextStyle" }, // You can define a new style for comment text if needed
          ],
          style: "keyValue",
        });
      }
    });
  }

  // Add History section
  documentDefinition.content.push({ text: "History", style: "subheader" });
  const historyItems = [
    { key: "Last Edited By", value: data.modifiedBy || "N/A" },
    {
      key: "Last Edited",
      value: data.modifiedDatetime
        ? formatDateTime(data.modifiedDatetime)
        : "N/A",
    },
    { key: "Created By", value: data.createdBy || "N/A" },
    {
      key: "Created",
      value: data.createdDatetime
        ? formatDateTime(data.createdDatetime)
        : "N/A",
    },
  ];
  historyItems.forEach((item) => {
    documentDefinition.content.push({
      stack: [
        { text: item.key, style: "keyValue1" }, // Use keyValue1 for the key to match the style
        { text: item.value, style: "keyValue" }, // Use keyValue for the value
      ],
      style: "keyValue",
    });
  });

  // Generate PDF
  pdfMake
    .createPdf(documentDefinition)
    .download(`${data.id}-${data.projectName}.pdf`);
};
