// SnackbarComponent.tsx
import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { useSnackbarContext } from "../../providers/SnackbarProvider";

const SnackbarComponent: React.FC = () => {
  const { open, message, severity, closeSnackbar } = useSnackbarContext();

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Alert onClose={closeSnackbar} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;
