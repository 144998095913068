import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

interface StatusOption {
  label: string;
  value: number;
}

interface EditableStatusProps {
  takedownId: string;
  statusValue: number;
  onUpdateStatus: (takedownId: string, newStatusValue: number) => Promise<void>;
}

const statusOptions: StatusOption[] = [
  { label: "Not received yet", value: 1 },
  { label: "Received  with errors", value: 3 },
  { label: "Received", value: 2 },
  { label: "Closed", value: 4 },
];

const EditableStatus: React.FC<EditableStatusProps> = ({
  takedownId,
  statusValue,
  onUpdateStatus,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showIcon, setShowIcon] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<number>(statusValue);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = async (value: number) => {
    try {
      await onUpdateStatus(takedownId, value);
      setCurrentStatus(value);
    } catch (error) {
      console.error("Failed to update status:", error);
      // Handle error state and user feedback here
    } finally {
      handleClose();
    }
  };

  const getStatusLabel = (value: number) => {
    const status = statusOptions.find((status) => status.value === value);
    return status ? status.label : "Unknown Status";
  };

  return (
    <>
      <Button
        aria-controls="status-menu"
        aria-haspopup="true"
        onClick={handleClick}
        onMouseOver={() => setShowIcon(true)}
        onMouseOut={() => setShowIcon(false)}
        sx={{
          textTransform: "none",
          color: "unset !important",
        }}
        endIcon={
          <EditIcon
            sx={{
              visibility: showIcon ? "visible" : "hidden",
              textTransform: "none",
            }}
          />
        }
      >
        {getStatusLabel(currentStatus)}
      </Button>
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {statusOptions.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === currentStatus}
            onClick={() => handleMenuItemClick(option.value)}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default EditableStatus;
