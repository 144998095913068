import React from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";

/**
 * Renders a sign-out button
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = (logoutType) => {
    if (logoutType === "popup") {
      instance.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/",
      });
    } else if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
  };
  return (
    <Button
      variant="outlined"
      sx={{ color: (theme) => theme.palette.common.white }}
      onClick={() => handleLogout("redirect")}
    >
      Sign Out
    </Button>
  );
};
