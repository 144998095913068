import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// @ts-ignore
import SwipeableViews from "react-swipeable-views-react-18-fix";
import {
  Stack,
  Typography,
  Divider,
  Grid,
  IconButton,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
  AppBar,
  ListItem,
  ListItemText,
  Toolbar,
  FormHelperText,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import axiosInstance from "../../utils/axiosInstance";
import Dropdown from "../../components/Dropdown";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { AttachFile } from "@mui/icons-material";
import NoRows from "../../components/DataGrid/animations/NoRows";
import { useAccount, useMsal } from "@azure/msal-react";
import Form from "../../components/Form/Form";
import { FormikProps } from "formik";
import * as Yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarContext } from "../../providers/SnackbarProvider";

const theme = createTheme();

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, height: 400, width: "100%" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

type FormValues = {
  region: string;
  type: string;
  projectNumber: string;
  projectName: string;
  section: string;
  addresses: {
    address: string;
    jobNumber: string;
    lot: string;
    block: string;
    price: string;
  }[];
};

type Project = {
  projectId: string;
  projectName: string;
  projectNumber: string;
};

export default function Create() {
  const navigate = useNavigate();
  const { accounts } = useMsal();
  const { showSnackbar } = useSnackbarContext();
  const fileInputRef = React.useRef(null);
  const account = useAccount(accounts[0]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [availableRegions, setAvailableRegions] = React.useState<
    {
      value: string;
      text: string;
    }[]
  >([]);
  const [projects, setProjects] = React.useState<Project[]>([]);
  const [sections, setSections] = React.useState<
    {
      text: string;
      value: string;
    }[]
  >([]);
  const [jobNumbers, setJobNumbers] = React.useState<
    {
      text: string;
      value: string;
    }[]
  >([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [values, SetValues] = useState<FormValues>({
    region: "",
    type: "",
    projectNumber: "",
    projectName: "",
    section: "",
    addresses: [],
  });

  const [addressState, setAddressState] = useState({
    address: "",
    lot: "",
    block: "",
    price: "",
    jobNumber: "",
  });
  const [addressError, setAddressError] = useState<string>();
  const [projectError, setProjectError] = useState<string>();
  const [sectionError, setSectionError] = useState<string>();
  const [showMaxCharWarning, setShowMaxCharWarning] =
    React.useState<boolean>(false);
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = React.useState<string[]>([]);
  const [selectedProject, setSelectedProject] = React.useState<Project | null>(
    null
  );
  const [attachments, setAttachments] = React.useState<
    {
      name: string;
      size: number;
      type: string;
      url: string;
    }[]
  >([]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setTabIndex(index);
  };

  const addComment = () => {
    if (!newComment.trim().length) return;
    setComments([newComment.trim(), ...comments]);
    setTabIndex(0);
    setNewComment("");
  };

  const handleSubmit = async (_values: FormValues) => {
    if (values.addresses.length === 0) {
      setAddressError("At least 1 address is required");
      return;
    }
    if (values.projectName.length === 0) {
      setProjectError("Project Name is required");
      return;
    }
    setProjectError("");
    setAddressError("");
    setSectionError("");
    createTakedown(_values);
  };

  const createTakedown = async (_values: FormValues) => {
    setLoading(true);
    try {
      await axiosInstance
        .post("/api/CreateTakedown", {
          typeId: _values.type === "cash" ? 2 : 1,
          titleStatusId: 1,
          region: _values.region,
          projectNumber: values.projectNumber,
          projectName: values.projectName,
          brixProjectId: parseInt(selectedProject?.projectId as string),
          section: values.section.toString(),
          guaranteeFileNumber: "",
          isActive: true,
          createdBy: account?.name || "",
          modifiedBy: account?.name || "",
          takedownLots: values.addresses.map((address) => ({
            jobNumber: address.jobNumber,
            addressNumber: "",
            addressStreet: address.address,
            block: address.block,
            price: address.price,
            lot: address.lot,
            isActive: true,
            createdBy: account?.name || "",
            modifiedBy: account?.name || "",
          })),
        })
        .then(async (res) => {
          const { results: strTakedownId } = res.data;
          if (comments.length) {
            comments.forEach((comment) => {
              axiosInstance
                .post("/api/CreateTakeDownComment", {
                  takedownId: parseInt(strTakedownId),
                  comment: comment,
                  isActive: true,
                  createdDateTime: new Date(),
                  createdBy: account?.name || "",
                  modifiedBy: account?.name || "",
                  modifiedDatetime: new Date(),
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          }

          if (attachments.length) {
            showSnackbar("Uploading Attachments", "success");
            const uploadPromises = attachments.map(
              (attachment, attachmentIndex) => {
                return axiosInstance
                  .get(`/api/GetURLtoAttach/${attachment.name}`)
                  .then((res) => {
                    if (res.status === 200) {
                      const { successMsg: urlToAttach } = res.data;
                      return new Promise<void>((resolve, reject) => {
                        const xhr = new XMLHttpRequest();
                        xhr.open("PUT", urlToAttach, true);
                        xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
                        xhr.onload = function () {
                          if (xhr.status >= 200 && xhr.status < 300) {
                            axiosInstance
                              .post("/api/CreateAttachment", {
                                attachmentName: attachment.name,
                                takedownId: parseInt(strTakedownId),
                                createdBy: account?.name || "",
                                modifiedBy: account?.name || "",
                                isActive: true,
                                sasUrl: urlToAttach,
                              })
                              .then((res) => {
                                if (res.status === 200) {
                                  resolve();
                                } else {
                                  reject(
                                    `There was an error creating the attachment`
                                  );
                                }
                              })
                              .catch((err) => {
                                reject(
                                  `There was an error creating the attachment: ${err}`
                                );
                              });
                          } else {
                            reject(`Upload failed: ${xhr.responseText}`);
                          }
                        };
                        if (fileInputRef.current) {
                          const fileInput =
                            fileInputRef.current as HTMLInputElement;
                          const file = fileInput.files?.[attachmentIndex];
                          xhr.send(file as Blob);
                        }
                      });
                    } else {
                      return Promise.reject(
                        "There was an error creating the url"
                      );
                    }
                  });
              }
            );

            try {
              await Promise.all(uploadPromises);
              console.error("All attachments uploaded successfully");
            } catch (error) {
              showSnackbar("Error uploading attachments", "error");
              console.error(`Error uploading attachments: ${error}`);
            }
          }

          return res;
        })
        .then((res) => {
          if (res.data.httpStatusCode === 500) {
            showSnackbar("Error creating takedown", "error");
          } else {
            showSnackbar("Successfully created takedown", "success");
            navigate("/edit/" + res.data.results);
          }
        });
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const handleFileChange = (event: {
    target: { files: any[]; value: any };
  }) => {
    const file = event.target.files[0];
    const maxSizeInBytes = 20 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      showSnackbar("File exceeds maximum size of 20mb", "error");
      return;
    }
    if (file) {
      setTabIndex(1);
      setAttachments([
        {
          name: file.name,
          size: file.size,
          type: file.type,
          url: event.target.value,
        },
        ...attachments,
      ]);
    }
  };

  const loadRegions = async () => {
    axiosInstance
      .get(`/api/getRegions`)
      .then((res) => {
        const regions = res.data.results;
        setAvailableRegions(
          regions.map((region: string) => ({
            value: region,
            text: region,
          }))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const loadProjects = async () => {
    if (!values.region) return; // alert error somehow in the ui
    axiosInstance
      .get(`/api/GetProjectsbyRegion/${values.region}`)
      .then((res) => {
        setProjects(
          res.data.results.sort(
            (a: { projectName: string }, b: { projectName: any }) =>
              a.projectName.localeCompare(b.projectName)
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function customSort(array: string[]): string[] {
    return array.sort((a, b) => {
      const numA = Number(a);
      const numB = Number(b);

      const isANumber = !isNaN(numA) && isFinite(numA);
      const isBNumber = !isNaN(numB) && isFinite(numB);

      // Both are numeric strings: sort as numbers
      if (isANumber && isBNumber) {
        return numA - numB;
      }

      // One is numeric, the other is non-numeric: numeric strings first
      if (isANumber) return -1;
      if (isBNumber) return 1;

      // Both are non-numeric strings: alphanumeric strings before alphabetic strings
      const aIsAlphanumeric = /\d/.test(a);
      const bIsAlphanumeric = /\d/.test(b);

      if (aIsAlphanumeric && !bIsAlphanumeric) return -1;
      if (!aIsAlphanumeric && bIsAlphanumeric) return 1;

      // Both are of the same type: sort alphabetically
      return a.localeCompare(b);
    });
  }

  const loadSections = async () => {
    if (!values.projectNumber) {
      // Display error message in UI
      // e.g., setError("Project number is required.");
      return;
    }

    try {
      const response = await axiosInstance.get(
        `/api/GetSectionsbyProjectNumber/${values.projectNumber}`
      );
      const filteredAndSortedSections = customSort(response.data.results);

      const sectionObjects = filteredAndSortedSections.map(
        (section: string) => ({
          text: section,
          value: section,
        })
      );

      setSections(sectionObjects);
    } catch (err) {
      console.error(err);
      // Handle the error in the UI
      // e.g., setError("Failed to load sections.");
    }
  };

  const loadJobNumbers = async () => {
    if (!values.projectNumber && !values.section) return; // alert error somehow in the ui
    axiosInstance
      .get(
        `/api/GetJobNumbersbyProjectSection/${values.projectNumber}/${values.section}`
      )
      .then((res) => {
        setJobNumbers(
          res.data.results.map((job: string) => {
            return {
              text: job,
              value: job,
            };
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    const close = document.getElementsByClassName(
      "MuiAutocomplete-clearIndicator"
    )[0];

    if (close) {
      close.addEventListener("click", () => {
        SetValues({
          ...values,
          section: "",
        });
        setSections([]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject]);

  React.useEffect(() => {
    if (values.projectNumber) loadSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.projectNumber]);

  React.useEffect(() => {
    if (values.region) loadProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.region]);

  React.useEffect(() => {
    if (selectedProject && values.section) {
      loadJobNumbers();
    } else {
      setJobNumbers([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, values.section]);

  React.useEffect(() => {
    loadRegions();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="lg"
        sx={{ mb: 8, mt: 4, maxWidth: "1080px !important" }}
      >
        <CssBaseline />
        <Box
          width={"100%"}
          sx={{
            mt: (theme) => theme.spacing(1),
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Form
            validateOnChange={false}
            validateOnBlur={true}
            validateOnMount={false}
            validationSchema={Yup.object().shape({
              region: Yup.string().required("Region is required"),
              type: Yup.string().required("Type is required"),
            })}
            onSubmitHandle={handleSubmit}
            initialValues={values}
          >
            {(formik: FormikProps<FormValues>) => (
              <>
                <Box
                  width={"100%"}
                  sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    py: (theme) => theme.spacing(2),
                    px: (theme) => theme.spacing(2),
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography
                      variant="h5"
                      sx={{
                        fontFamily: "IBM Plex Sans Arabic",
                        fontWeight: 500,
                      }}
                    >
                      Create Lot Take Down
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={2}
                    sx={{
                      flex: 1,
                      direction: "row",
                      mt: { sm: 0, md: 0 },
                    }}
                  >
                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      type="submit"
                      onClick={() => {
                        if (!values.addresses.length) {
                          setAddressError("At least 1 address is required. ");
                        } else {
                          setAddressError("");
                        }
                        if (!values.projectName.length) {
                          setProjectError("Project Name is required");
                        } else {
                          setProjectError("");
                        }
                        if (!values.section) {
                          setSectionError("Section is required");
                        } else {
                          setSectionError("");
                        }
                      }}
                    >
                      Create
                    </LoadingButton>
                  </Stack>
                </Box>
                <Box width={"100%"} paddingX={3}>
                  <Box
                    paddingY={(theme) => theme.spacing(2)}
                    paddingX={1}
                    width={"100%"}
                  >
                    <Stack direction={"row"} spacing={2} mt={3}>
                      <Dropdown
                        options={availableRegions}
                        id="region"
                        label={"Region"}
                        name={`region`}
                        variant="outlined"
                        value={formik.values.region}
                        onChange={(e) => {
                          formik?.handleChange(e);
                          SetValues({
                            ...values,
                            region: e.target.value,
                          });
                          if (
                            formik?.values.projectName.length ||
                            values.projectName.length
                          ) {
                            formik.setFieldValue("projectName", "");
                            formik.setFieldValue("projectNumber", "");
                            formik.setFieldValue("section", "");
                            SetValues({
                              ...values,
                              region: e.target.value,
                              projectName: "",
                              projectNumber: "",
                              section: "",
                            });
                            setSelectedProject(null);
                          }
                        }}
                        error={Boolean(formik.errors?.region)}
                        helperText={formik.errors?.region as string}
                      />
                      <Dropdown
                        options={[
                          {
                            value: "cash",
                            text: "Cash",
                          },
                          {
                            value: "interim",
                            text: "Interim",
                          },
                        ]}
                        id="type"
                        label="Type"
                        name="type"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={formik.values.type}
                        onChange={formik?.handleChange}
                        error={Boolean(formik.errors?.type)}
                        helperText={formik.errors?.type as string}
                      />
                    </Stack>
                    <Stack direction={"row"} spacing={2} mt={3}>
                      <Autocomplete
                        id="projectName"
                        options={projects}
                        value={selectedProject}
                        fullWidth
                        // @ts-ignore
                        name="projectName"
                        noOptionsText={
                          !projects.length
                            ? "Select a region first"
                            : "No Results"
                        }
                        getOptionLabel={(option) => option.projectName}
                        onChange={(event, newValue) => {
                          setSelectedProject(newValue);
                          formik?.handleChange(event);
                          SetValues({
                            ...values,
                            projectName: newValue ? newValue.projectName : "",
                            projectNumber: newValue
                              ? newValue.projectNumber
                              : "",
                            section: "",
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="projectName"
                            label="Project Name"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            error={Boolean(projectError)}
                            helperText={projectError ? projectError : ""}
                          />
                        )}
                      />

                      <TextField
                        fullWidth
                        id="projectNumber"
                        label="Project Number"
                        name="projectNumber"
                        disabled
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={values.projectNumber}
                        error={Boolean(projectError)}
                        helperText={projectError ? "Select a project name" : ""}
                      />
                      <Dropdown
                        options={sections}
                        id="section"
                        label="Section"
                        name="section"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={values.section}
                        onChange={(e) =>
                          SetValues({ ...values, section: e.target.value })
                        }
                        error={Boolean(sectionError)}
                        helperText={sectionError ? sectionError : ""}
                        noOptionsText={
                          !sections.length ? "Select a project first" : ""
                        }
                      />
                    </Stack>
                  </Box>
                  <Box
                    paddingY={(theme) => theme.spacing(1)}
                    paddingX={1}
                    width={"100%"}
                  >
                    <Accordion
                      square={true}
                      defaultExpanded
                      elevation={0}
                      sx={{
                        mt: 2,
                        border: "1px solid rgba(0, 0, 0, 0.12)",
                        width: "100%",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          Addresses {`(${values.addresses.length})`}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack direction="column" spacing={0} mt={3}>
                          {values.addresses.map((address, index) => (
                            <Grid container spacing={1} mt={2} key={index}>
                              <Grid item xs={5}>
                                <TextField
                                  fullWidth
                                  disabled
                                  id="address"
                                  label="Address"
                                  name="address"
                                  variant="outlined"
                                  value={address.address}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <TextField
                                  fullWidth
                                  disabled
                                  id="jobNumber"
                                  label="Job Number"
                                  name="jobNumber"
                                  variant="outlined"
                                  value={address.jobNumber}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <TextField
                                  fullWidth
                                  disabled
                                  id="lot"
                                  label="Lot"
                                  name="lot"
                                  variant="outlined"
                                  value={address.lot}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <TextField
                                  disabled
                                  fullWidth
                                  id="block"
                                  label="Block"
                                  name="block"
                                  variant="outlined"
                                  value={address.block}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <TextField
                                  fullWidth
                                  disabled
                                  id="price"
                                  type="number"
                                  label="Price"
                                  name="price"
                                  variant="outlined"
                                  value={address.price}
                                  InputLabelProps={{ shrink: true }}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={1}
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <Tooltip title="Remove Address">
                                  <IconButton
                                    onClick={() => {
                                      SetValues({
                                        ...values,
                                        addresses: [
                                          ...values.addresses.slice(0, index),
                                          ...values.addresses.slice(index + 1),
                                        ],
                                      });
                                    }}
                                  >
                                    <RemoveCircleOutlineIcon />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          ))}
                          {!values.addresses.length && (
                            <NoRows title="Add an address below" />
                          )}
                        </Stack>
                      </AccordionDetails>
                    </Accordion>
                    <Box width={"100%"}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        align="left"
                        sx={{
                          mb: 2,
                        }}
                      >
                        Add New Addresses
                      </Typography>
                    </Box>
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          <TextField
                            fullWidth
                            id="address"
                            label="Address"
                            name="address"
                            variant="outlined"
                            value={addressState.address}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              // Check if the length exceeds 5 characters
                              if (newValue.length > 50) {
                                // Show warning and prevent updating the state
                                setShowMaxCharWarning(true);
                              } else {
                                // Hide warning and update the state
                                setShowMaxCharWarning(false);
                                setAddressState({
                                  ...addressState,
                                  address: e.target.value,
                                });
                              }
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Dropdown
                            options={jobNumbers}
                            id="jobNumber"
                            label="Job Number"
                            name="jobNumber"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            value={addressState.jobNumber}
                            onChange={(e) =>
                              setAddressState({
                                ...addressState,
                                jobNumber: e.target.value,
                              })
                            }
                            noOptionsText={
                              !values.section.length
                                ? "Select a project and section first"
                                : ""
                            }
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextField
                            fullWidth
                            id="lot"
                            label="Lot"
                            name="lot"
                            variant="outlined"
                            value={addressState.lot}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              // Check if the length exceeds 5 characters
                              if (newValue.length > 5) {
                                // Show warning and prevent updating the state
                                setShowMaxCharWarning(true);
                              } else {
                                // Hide warning and update the state
                                setShowMaxCharWarning(false);
                                if (/^[a-zA-Z0-9]*$/.test(newValue)) {
                                  setAddressState({
                                    ...addressState,
                                    lot: newValue,
                                  });
                                }
                              }
                            }}
                            InputLabelProps={{ shrink: true }}
                            // inputProps={{
                            //   maxLength: 5,
                            // }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextField
                            fullWidth
                            id="block"
                            label="Block"
                            name="block"
                            variant="outlined"
                            value={addressState.block}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              // Check if the length exceeds 5 characters
                              if (newValue.length > 5) {
                                // Show warning and prevent updating the state
                                setShowMaxCharWarning(true);
                              } else {
                                // Hide warning and update the state
                                setShowMaxCharWarning(false);
                                if (/^[a-zA-Z0-9]*$/.test(newValue)) {
                                  setAddressState({
                                    ...addressState,
                                    block: newValue,
                                  });
                                }
                              }
                            }}
                            InputLabelProps={{ shrink: true }}
                            // inputProps={{
                            //   maxLength: 5,
                            // }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            id="price"
                            label="Price"
                            name="price"
                            variant="outlined"
                            value={addressState.price}
                            onChange={(e) => {
                              let newValue = e.target.value;
                              if (newValue.length > 10) {
                                // Show warning and prevent updating the state
                                setShowMaxCharWarning(true);
                              } else {
                                setShowMaxCharWarning(false);
                                // Regular expression to allow only positive numbers with up to two decimal places
                                const decimalRegex = /^\d*(\.\d{0,2})?$/;

                                // If the value starts with a decimal, prepend '0'
                                if (newValue.startsWith(".")) {
                                  newValue = "0" + newValue;
                                }

                                // Check if the new value is an empty string or matches the decimal regex
                                if (
                                  newValue === "" ||
                                  decimalRegex.test(newValue)
                                ) {
                                  setAddressState({
                                    ...addressState,
                                    price: newValue,
                                  });
                                }
                              }
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title="Add Address">
                            <IconButton
                              onClick={() => {
                                setShowMaxCharWarning(false);
                                SetValues({
                                  ...values,
                                  addresses: [
                                    ...values.addresses,
                                    {
                                      address: addressState.address.trim(),
                                      lot: addressState.lot.trim(),
                                      block: addressState.block.trim(),
                                      price: addressState.price,
                                      jobNumber: addressState.jobNumber,
                                    },
                                  ],
                                });
                                setShowMaxCharWarning(false);
                                setAddressState({
                                  address: "",
                                  lot: "",
                                  block: "",
                                  price: "",
                                  jobNumber: "",
                                });
                                setAddressError("");
                              }}
                              disabled={
                                !addressState.address.trim() ||
                                !addressState.lot.trim() ||
                                !addressState.price.trim() ||
                                !addressState.jobNumber.trim()
                              }
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                          <FormHelperText
                            error={true}
                            sx={{
                              m: 0,
                              p: 0,
                            }}
                          >
                            {addressError as string}
                            {showMaxCharWarning &&
                              "Address must be 50 Characters or less. Lot and Block must be 5 characters or less. Price must be 10 characters or less."}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    paddingX: 4,
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      width: "100%",
                      ".swipeableViews::-webkit-scrollbar": {
                        display: "none",
                      },
                      mt: 1,
                    }}
                  >
                    <AppBar position="static">
                      <Tabs
                        value={tabIndex}
                        onChange={handleChange}
                        indicatorColor="secondary"
                        textColor="inherit"
                        variant="fullWidth"
                      >
                        <Tab label="Comments" {...a11yProps(0)} />
                        <Tab label="Attachments" {...a11yProps(1)} />
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                      index={tabIndex}
                      onChangeIndex={handleChangeIndex}
                      style={{
                        height: "420px",
                        width: "100%",
                        display: "block",
                        borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      <TabPanel
                        value={tabIndex}
                        index={0}
                        dir={theme.direction}
                      >
                        <Box
                          sx={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Stack
                            divider={<Divider />}
                            direction="column"
                            spacing={0}
                            sx={{
                              width: "100%",
                              bgcolor: "background.paper",
                            }}
                          >
                            {comments.length ? (
                              comments.map((comment) => (
                                <ListItem
                                  alignItems="flex-start"
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <ListItemText
                                    primary={comment}
                                    secondary={
                                      <React.Fragment>
                                        {account?.name || ""} &nbsp; &mdash;
                                        &nbsp;
                                        {moment.utc().format("L")}
                                      </React.Fragment>
                                    }
                                  />
                                </ListItem>
                              ))
                            ) : (
                              <Box p={8}>
                                <NoRows title="No Comments" />
                              </Box>
                            )}
                          </Stack>
                        </Box>
                      </TabPanel>
                      <TabPanel
                        value={tabIndex}
                        index={1}
                        dir={theme.direction}
                      >
                        <Box
                          sx={{
                            display: "block",
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <Stack
                            divider={<Divider />}
                            direction="column"
                            spacing={0}
                            sx={{
                              width: "100%",
                              bgcolor: "background.paper",
                            }}
                          >
                            {attachments.length ? (
                              attachments.map((attachment) => (
                                <ListItem
                                  alignItems="flex-start"
                                  sx={{
                                    width: "100%",
                                  }}
                                >
                                  <ListItemText
                                    primary={attachment.name}
                                    secondary={
                                      <React.Fragment>
                                        {account?.name || ""} &nbsp; &mdash;
                                        &nbsp;
                                        {moment.utc().format("L")}
                                      </React.Fragment>
                                    }
                                  />
                                </ListItem>
                              ))
                            ) : (
                              <Box p={8}>
                                <NoRows title="No Attachments" />
                              </Box>
                            )}
                          </Stack>
                        </Box>
                      </TabPanel>
                    </SwipeableViews>
                    <Box
                      sx={{
                        display: "flex",
                        flex: 1,
                        borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
                        borderRight: "1px solid rgba(0, 0, 0, 0.1)",
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                      }}
                    >
                      <AppBar
                        position="static"
                        color="transparent"
                        elevation={0}
                      >
                        <Toolbar
                          sx={{
                            minHeight: "45px !important",
                          }}
                        >
                          <Tooltip title="Attach a file">
                            <div>
                              <input
                                style={{ display: "none" }}
                                id="upload-file"
                                type="file"
                                accept=".txt, .pdf, .png, .jpg, text/plain, application/pdf, image/png, image/jpeg"
                                ref={fileInputRef}
                                // @ts-ignore
                                onChange={handleFileChange}
                              />
                              <label htmlFor="upload-file">
                                <IconButton
                                  aria-label="upload picture"
                                  component="span"
                                  // onClick={handleUploadClick}
                                >
                                  <AttachFile />
                                </IconButton>
                              </label>
                            </div>
                          </Tooltip>
                          <TextField
                            fullWidth
                            multiline
                            minRows={1}
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                            variant="standard"
                            placeholder="Add a comment..."
                            InputProps={{ disableUnderline: true }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                addComment();
                              }
                            }}
                          />
                          <Tooltip title="Add comment">
                            <IconButton
                              slot="end"
                              size="small"
                              onClick={addComment}
                              disabled={!newComment.trim().length}
                            >
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </Toolbar>
                      </AppBar>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Form>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
