import {
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  Theme,
  Tooltip,
} from "@mui/material";
import React, { ReactNode } from "react";
import CloseIcon from "@mui/icons-material/Close";

interface ReusableDialogProps {
  content: ReactNode;
  setIsOpen: (state: boolean) => void;
  setIsClosed: () => void;
  isOpen: boolean;
  buttonText?: string;
  title: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  type?: string;
  icon?: ReactNode;
  buttonColor?: "primary" | "secondary" | "error" | "info" | "success";
  buttonVariant?: "contained" | "outlined" | "text";
}

const ReusableDialog: React.FC<ReusableDialogProps> = ({
  content,
  buttonText,
  title,
  setIsOpen,
  setIsClosed,
  maxWidth,
  isOpen,
  type,
  icon,
  buttonColor = "primary",
  buttonVariant = "contained",
}) => {
  const handleClickOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsClosed();
  };

  return (
    <div>
      {type !== "icon" ? (
        <Tooltip title={title}>
          <Button
            variant={buttonVariant}
            onClick={handleClickOpen}
            color={buttonColor}
          >
            {buttonText}
          </Button>
        </Tooltip>
      ) : (
        <Tooltip title={title}>
          <IconButton onClick={handleClickOpen}>{icon}</IconButton>
        </Tooltip>
      )}
      <Dialog maxWidth={maxWidth} open={isOpen} onClose={handleClose} fullWidth>
        <DialogTitle
          sx={(theme: Theme) => ({
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
          })}
        >
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 10,
            color: "#ffff",
          }}
        >
          <CloseIcon />
        </IconButton>
        {content}
      </Dialog>
    </div>
  );
};

export default ReusableDialog;
