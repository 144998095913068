import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro";
import {
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
  Button,
  Link,
  SelectChangeEvent,
} from "@mui/material";
import axiosInstance from "../../utils/axiosInstance";
import NoRows from "../../components/DataGrid/animations/NoRows";
import SearchIcon from "@mui/icons-material/Search";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import Comments from "../../modals/Comments";
import Attachments from "../../modals/Attachments";
import EditableGFNumber from "../../components/Buttons/EditGFNumber";
import EditableStatus from "../../components/Buttons/EditStatus";
import { useAccount, useMsal } from "@azure/msal-react";
import { useSnackbarContext } from "../../providers/SnackbarProvider";
import { generatePDF } from "../../utils/generatePDF";

const theme = createTheme();

type FilterItem = {
  id?: string | number;
  field: string;
  operator: string;
  value: string | string[]; // Allow value to be string or array of strings
};

type Filters = {
  items: FilterItem[];
  quickFilterValues: string[];
};

type Project = {
  projectId: string;
  projectName: string;
  projectNumber: string;
};

const defaultFilter: Filters = {
  items: [
    {
      id: "status-multi",
      field: "status",
      operator: "isAnyOf",
      value: ["Received", "Received with errors", "Not received yet"],
    },
  ],
  quickFilterValues: [""],
};

export default function Home() {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const { showSnackbar } = useSnackbarContext();
  const [projects, setProjects] = React.useState<Project[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [documents, setDocuments] = React.useState<any>([]);
  const [availableRegions, setAvailableRegions] = React.useState<string[]>([
    "All",
  ]);
  const [filt, setFilt] = React.useState<Filters>(defaultFilter);
  const [projectName, setProjectName] = React.useState<string>("All");
  const [region, setRegion] = React.useState<string>("All");
  const [type, setType] = React.useState<string>("All");
  const [status, setStatus] = React.useState<string>("Active");

  const loadTakeDowns = () => {
    axiosInstance
      .get(`/api/GetTakeDownReport`)
      .then((res) => {
        setDocuments([
          //@ts-ignore
          ...formatParents(res.data.results),
          //@ts-ignore
          ...flattenData(res.data.results),
        ]);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const formatParents = (data: any) => {
    return data.map((item: any) => {
      return {
        id: `${item.id}`,
        combinedField: `${item.id} - ${item.projectName}`,
        status: item.status,
        region: item.region,
        projectName: item.projectName,
        section: item.section,
        type: item.type,
        guaranteeFileNumber: item.guaranteeFileNumber,
        hierarchy: [`${item.id} - ${item.projectName}`],
      };
    });
  };

  const flattenData = (data: any) => {
    const flattened: any = [];

    data.forEach((project: any) => {
      project.takedownLots.forEach((takedownLot: any) => {
        const newItem = {
          ...takedownLot,
          id: `${takedownLot.id}/${takedownLot.addressStreet}`,
          hierarchy: [
            `${project.id} - ${project.projectName}`,
            `${takedownLot.id}/${takedownLot.addressStreet}`,
          ],
          status: project.status,
          region: project.region,
          projectName: project.projectName,
          section: project.section,
          type: project.type,
          parentId: project.id,
          childId: takedownLot.id,
          isActive: takedownLot.isActive,
        };
        if (newItem.isActive === true) {
          flattened.push(newItem);
        }
      });
    });
    return flattened;
  };

  // Update only the related filter and keep the others
  // const updateFilter = (field: string, value: string): void => {
  //   setFilt((prevFilt) => {
  //     const otherItems = prevFilt.items.filter((item) => item.field !== field);
  //     const newItems =
  //       value === "All"
  //         ? otherItems
  //         : [...otherItems, { field, operator: "equals", value }];
  //     return { ...prevFilt, items: newItems };
  //   });
  // };

  const updateFilter = (field: string, value: string | string[]): void => {
    setFilt((prevFilt) => {
      const otherItems = prevFilt.items.filter((item) => item.field !== field);

      let newItems;
      if (Array.isArray(value)) {
        // Use 'isAnyOf' operator for arrays
        newItems = [
          {
            id: `${field}-multi`,
            field,
            operator: "isAnyOf",
            value: value,
          },
        ];
      } else {
        // Use 'equals' operator for single values
        newItems =
          value === "All"
            ? []
            : [
                {
                  id: `${field}-${value}`,
                  field,
                  operator: "equals",
                  value,
                },
              ];
      }

      return { ...prevFilt, items: [...otherItems, ...newItems] };
    });
  };

  // Event handlers
  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setType(newValue);
    updateFilter("type", newValue);
  };

  const handleRegionChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setRegion(newValue);
    updateFilter("region", newValue);
  };

  const handleProjectNameChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setProjectName(newValue);
    updateFilter("projectName", newValue);
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const newValue = event.target.value;
    setStatus(newValue);

    if (newValue === "Closed") {
      updateFilter("status", "Closed");
    } else {
      // Pass an array of values for the 'Active' status
      updateFilter("status", [
        "Received",
        "Received with errors",
        "Not received yet",
      ]);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setFilt((prevFilt) => ({
      ...prevFilt,
      quickFilterValues: [newValue],
    }));
  };

  const updateGFNumber = async (takedownId: string, newGFNumber: string) => {
    const response = await axiosInstance.post(`/api/UpdateTakedown`, {
      id: parseInt(takedownId),
      guaranteeFileNumber: newGFNumber,
      //titleStatusId: 2,
      modifiedBy: account?.name || "",
    });
    if (response.status === 200) {
      showSnackbar("GF # updated successfully", "info");
    } else {
      showSnackbar("There was an issue updating the GF #.", "error");
    }
  };

  const updateStatus = async (takedownId: string, newStatusValue: number) => {
    const response = await axiosInstance.post(`/api/UpdateTakedown`, {
      id: parseInt(takedownId),
      titleStatusId: newStatusValue,
      modifiedBy: account?.name || "",
    });
    if (response.status === 200) {
      showSnackbar("Status updated successfully", "info");
    } else {
      showSnackbar("There was an issue updating the status.", "error");
    }
  };

  function getValueFromLabel(label: string | number) {
    const labelToValueMap = {
      "Not received yet": 1,
      "Received with errors": 3,
      Received: 2,
      Closed: 4,
    };

    // @ts-ignore
    return labelToValueMap[label] || null; // Returns null if the label does not match
  }

  const loadRegions = async () => {
    axiosInstance
      .get(`/api/getRegions`)
      .then((res) => {
        const regions = res.data.results;
        setAvailableRegions(["All", ...regions]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const loadProjects = async () => {
    if (region === "All") {
      // Use a set to keep track of unique project names
      const projectNamesSet: Set<string> = new Set();

      documents.forEach((project: any) => {
        projectNamesSet.add(project.projectName);
      });

      // Convert the set back into an array
      // @ts-ignore
      const uniqueProjectNames = [...projectNamesSet];
      setProjects(
        uniqueProjectNames.map((project) => ({
          projectName: project,
          projectId: project,
          projectNumber: project,
        }))
      );
      return;
    } // alert error somehow in the ui
    axiosInstance
      .get(`/api/GetProjectsbyRegion/${region}`)
      .then((res) => {
        setProjects(
          res.data.results.sort(
            (a: { projectName: string }, b: { projectName: any }) =>
              a.projectName.localeCompare(b.projectName)
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  React.useEffect(() => {
    loadRegions();
  }, []);

  React.useEffect(() => {
    loadTakeDowns();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    loadProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents, region]);

  const columns: GridColDef[] = [
    {
      field: "type",
      headerName: "Type",
      minWidth: 150,
    },
    { field: "region", headerName: "Region", minWidth: 150 },
    {
      field: "projectName",
      headerName: "Project Name",
      minWidth: 150,
      filterable: true,
    },
    {
      field: "section",
      headerName: "Section",
      minWidth: 150,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "guaranteeFileNumber",
      headerName: "GF #",
      minWidth: 300,
      align: "center",
      headerAlign: "center",
      renderCell: (params) =>
        params.row.hierarchy.length <= 1 ? (
          <EditableGFNumber
            takedownId={params.id as any}
            gfNumber={params.value as any}
            onSave={updateGFNumber}
          />
        ) : null,
    },
    {
      field: "status",
      headerName: "Title Status",
      minWidth: 200,
      renderCell: (params) => {
        return params.row.hierarchy.length <= 1 ? (
          <EditableStatus
            takedownId={params.id as any}
            statusValue={getValueFromLabel(params.value)} // Current status value
            onUpdateStatus={updateStatus}
          />
        ) : null;
      },
    },
    {
      field: "combinedField",
      headerName: "Takedown Name",
      width: 0,
      maxWidth: 0,
      hideable: true,

      // ... other settings if needed
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "right",
      align: "right",
      minWidth: 200,
      flex: 1,
      filterable: false,
      sortable: false,
      hideable: false,
      pinnable: false,
      renderCell: ({ row }) => {
        return (
          row.hierarchy.length <= 1 && (
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="center"
              spacing={1}
              sx={{
                mr: (theme) => theme.spacing(2),
              }}
            >
              <Tooltip title="Edit">
                <IconButton size="small" href={`/edit/${row.id}`}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Comments takeDownID={row.id} />
              <Attachments takeDownID={row.id} />
              <Tooltip title="Print">
                <IconButton
                  size="small"
                  onClick={() => {
                    if (!row.id) return;
                    axiosInstance
                      .get(`/api/GetTakedownDetails/${row.id}`)
                      .then((res) => {
                        generatePDF(res.data.results);
                      });
                  }}
                >
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          )
        );
      },
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth={false}>
        <CssBaseline />
        <Box
          width={"100%"}
          sx={{
            mt: (theme) => theme.spacing(4),
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: "75vh",
              width: "100%",
              mt: (theme) => theme.spacing(2),
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                py: (theme) => theme.spacing(2),
              }}
            >
              <Stack
                sx={{
                  width: "70%",
                }}
                direction="row"
                alignItems="flex-start"
                spacing={2}
              >
                <FormControl
                  fullWidth
                  sx={{
                    maxWidth: 300,
                  }}
                >
                  <TextField
                    sx={{ maxWidth: "lg", width: "100%" }}
                    size="small"
                    label="Search"
                    variant="outlined"
                    onChange={handleSearchChange}
                    placeholder="Search..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon color="info" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{
                    maxWidth: 160,
                  }}
                >
                  <InputLabel>Type</InputLabel>
                  <Select
                    value={type}
                    label="Type"
                    size="small"
                    onChange={handleTypeChange}
                  >
                    {["All", "Interim", "Cash"].map((string, index) => (
                      <MenuItem key={index} value={string}>
                        {string}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{
                    maxWidth: 160,
                  }}
                >
                  <InputLabel>Region</InputLabel>
                  <Select
                    value={region}
                    label="Region"
                    size="small"
                    onChange={handleRegionChange}
                  >
                    {availableRegions.map((string, index) => (
                      <MenuItem key={index} value={string}>
                        {string}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{
                    maxWidth: 160,
                  }}
                >
                  <InputLabel>Project Name</InputLabel>
                  <Select
                    value={projectName}
                    label="Project Name"
                    size="small"
                    onChange={handleProjectNameChange}
                  >
                    <MenuItem key="All" value="All">
                      All
                    </MenuItem>
                    {projects.sort().map((string, index) => (
                      <MenuItem key={index} value={string.projectName}>
                        {string.projectName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{
                    maxWidth: 160,
                  }}
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={status}
                    label="Status"
                    size="small"
                    onChange={handleStatusChange}
                  >
                    <MenuItem key="Active" value="Active">
                      Active
                    </MenuItem>
                    <MenuItem key="Closed" value="Closed">
                      Closed
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={2}
                sx={{
                  flex: 1,
                  direction: "row",
                  mt: { sm: 2, md: 0 },
                }}
              >
                <Button href="/create" variant="contained">
                  Create
                </Button>
              </Stack>
            </Box>
            {documents && (
              <DataGridPro
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                treeData
                getTreeDataPath={(row) => {
                  return row.hierarchy || [];
                }}
                sx={{ mt: 1 }}
                rows={documents}
                columns={columns}
                groupingColDef={{
                  headerName: "Takedowns",
                  sortable: false,
                  align: "left",
                  minWidth: 300,
                  hideDescendantCount: true,
                  valueFormatter: (params) => {
                    // @ts-ignore
                    if (params.id && params.id.split("/").length > 1) {
                      return params.id.toString().split("/")[1]; // Return null if hierarchy length is greater than 1
                    } else {
                      return (
                        <Tooltip title="Edit">
                          <Link color="inherit" href={`/edit/${params.id}`}>
                            {params.value}
                          </Link>
                        </Tooltip>
                      );
                    }
                  },
                }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {
                      // Hide columns status and traderName, the other columns will remain visible
                      combinedField: false,
                    },
                  },
                  pagination: { paginationModel: { pageSize: 25 } },
                }}
                disableRowSelectionOnClick
                filterModel={filt}
                onFilterModelChange={(newFilterModel) =>
                  setFilt(newFilterModel as any)
                }
                //rowCount={documents.length}
                pagination
                slots={{
                  noRowsOverlay: NoRows,
                }}
                loading={loading}
              />
            )}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
