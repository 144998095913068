import axios from "axios";
import { functionAppScope } from "../authConfig";
import { msalInstance } from "../index";
import { InteractionRequiredAuthError, AccountInfo } from "@azure/msal-browser";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

function fetchAccessToken() {
  const account = msalInstance.getAllAccounts();

  return new Promise((resolve) => {
    msalInstance
      .acquireTokenSilent({ ...functionAppScope, account: account[0] })
      .then((response: any) => {
        resolve(response.accessToken);
      })
      .catch((e: any) => {
        if (e instanceof InteractionRequiredAuthError) {
          msalInstance.acquireTokenRedirect({
            ...functionAppScope,
            account: msalInstance.getActiveAccount() as AccountInfo,
          });
        }
      });
  });
}

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = await fetchAccessToken();
    if (accessToken) {
      // @ts-ignore
      config.headers["Authorization"] = `Bearer ${accessToken}`;
      config.headers["X-MS-TOKEN-AAD-ACCESS-TOKEN"] = `Bearer ${accessToken}`;
      config.headers["X-MS-TOKEN-AAD-ID-TOKEN"] = `Bearer ${accessToken}`;
      config.headers["X-MS-TOKEN-AAD-EXPIRES-ON"] = `Bearer ${accessToken}`;
      config.headers["X-MS-TOKEN-AAD-REFRESH-TOKEN"] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    console.log("error test");
    return error;
  }
);

axios.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    return error;
  }
);

export default axiosInstance;
