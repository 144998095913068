import React, { useState } from "react";
import {
  Button,
  IconButton,
  Input,
  InputAdornment,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

interface EditableGFNumberProps {
  takedownId: string;
  gfNumber: string;
  onSave: (takedownId: string, newGFNumber: string) => Promise<void>;
}

const EditableGFNumber: React.FC<EditableGFNumberProps> = ({
  takedownId,
  gfNumber,
  onSave,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [currentGFNumber, setCurrentGFNumber] = useState(gfNumber);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setCurrentGFNumber(gfNumber);
    setIsEditing(false);
    setShowIcon(false);
  };

  const handleSaveClick = async () => {
    setShowIcon(false);
    try {
      await onSave(takedownId, currentGFNumber);
      setIsEditing(false);
    } catch (error) {
      console.error("Failed to save GF#:", error);
      // Handle error state and user feedback here
    }
  };

  if (isEditing) {
    return (
      <Input
        value={currentGFNumber}
        onChange={(e) => {
          // Using a regular expression to allow only alphanumeric characters
          if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
            setCurrentGFNumber(e.target.value.toUpperCase());
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleSaveClick} aria-label="confirm">
              <CheckIcon />
            </IconButton>
            <IconButton onClick={handleCancelClick} aria-label="cancel">
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    );
  }

  return (
    <Button
      variant="text"
      sx={{
        color: "unset !important",
      }}
      endIcon={
        <EditIcon
          sx={{
            visibility: showIcon ? "visible" : "hidden",
            textTransform: "none",
          }}
        />
      }
      onClick={handleEditClick}
      onMouseOver={() => setShowIcon(true)}
      onMouseOut={() => setShowIcon(false)}
    >
      {currentGFNumber || (
        <Typography
          sx={{
            textTransform: "none",
          }}
          variant="subtitle2"
        >
          0
        </Typography>
      )}
    </Button>
  );
};

export default EditableGFNumber;
