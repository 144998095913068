import React, { useState, useEffect, memo } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  AppBar,
  Box,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Toolbar,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CommentIcon from "@mui/icons-material/Comment";
import axiosInstance from "../../utils/axiosInstance";
import { TakeDownCommentProps } from "../../types";
import { useAccount, useMsal } from "@azure/msal-react";
import moment from "moment";
import NoRows from "../../components/DataGrid/animations/NoRows";
import { useSnackbarContext } from "../../providers/SnackbarProvider";

type Props = {
  takeDownID: string;
};

const Comments = memo((props: Props) => {
  const { showSnackbar } = useSnackbarContext();
  const [open, setOpen] = useState(false);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const [newComment, setNewComment] = useState("");
  const [takeDownComment, setTakeDownComment] = useState<TakeDownCommentProps>({
    TakeDownId: parseInt(props.takeDownID),
    Comment: "",
    isActive: true,
    CreatedDateTime: new Date(),
    CreatedBy: account?.name,
    ModifiedDateTime: new Date(),
    ModifiedBy: account?.name,
  });

  const [comments, setComments] = useState<
    {
      id: number;
      takedownId: number;
      comment: string;
      isActive: boolean;
      createdDatetime: string;
      createdBy: string;
      modifiedDatetime: string;
      modifiedBy: string;
    }[]
  >([]);

  const loadComments = (id: string) => {
    axiosInstance
      .get(`/api/GetTakeDownComments/${id}`)
      .then((res) => {
        setComments(
          res.data.results.filter(
            (c: { isActive: boolean }) => c.isActive === true
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const saveComment = (comment: string) => {
    const updatedTakeDownComment = {
      ...takeDownComment,
      Comment: comment.trim(),
      CreatedDateTime: new Date(),
      ModifiedDateTime: new Date(),
    };

    axiosInstance
      .post(`/api/CreateTakeDownComment`, updatedTakeDownComment)
      .then((res) => {
        if (res.status === 200 && (res?.data?.results ?? -1) > 0) {
          setComments([
            {
              id: res.data.results,
              takedownId: updatedTakeDownComment.TakeDownId,
              comment: updatedTakeDownComment.Comment,
              isActive: updatedTakeDownComment.isActive,
              createdDatetime: moment
                .utc(updatedTakeDownComment.CreatedDateTime)
                .toLocaleString(),
              createdBy: updatedTakeDownComment.CreatedBy || "",
              modifiedDatetime: moment
                .utc(updatedTakeDownComment.ModifiedDateTime)
                .toLocaleString(),
              modifiedBy: updatedTakeDownComment.ModifiedBy || "",
            },
            ...comments,
          ]);

          setNewComment("");
        } else {
          showSnackbar("There was an error creating a comment", "error");
        }
      });
  };

  const handleAddNewCommentChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewComment(event.target.value);
  };

  const handleAddNewCommentKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && newComment.trim() !== "") {
      saveComment(newComment);
    }
  };

  const btnAddCommentClicked = () => {
    if (newComment.trim() !== "") {
      saveComment(newComment);
    }
  };

  useEffect(() => {
    if (open && props.takeDownID) {
      // Load comments only when the dialog opens and a takedown ID is present
      loadComments(props.takeDownID);
    }
  }, [open, props.takeDownID]);

  return (
    <>
      <ReusableDialog
        type="icon"
        icon={<CommentIcon />}
        setIsOpen={setOpen}
        setIsClosed={() => {
          setOpen(false);
          setComments([]);
        }}
        isOpen={open}
        maxWidth={"md"}
        content={
          <>
            <DialogContent
              sx={{
                padding: 0,
                minHeight: 400,
              }}
            >
              {comments.length ? (
                <Stack
                  divider={<Divider />}
                  direction="column"
                  spacing={0}
                  sx={{
                    bgcolor: "background.paper",
                  }}
                >
                  {comments.map((comment) => (
                    <ListItem
                      key={comment.id}
                      alignItems="flex-start"
                      sx={{
                        wordWrap: "break-word",
                      }}
                    >
                      <ListItemText
                        primary={comment.comment}
                        secondary={
                          <React.Fragment>
                            {comment.createdBy} &nbsp; &mdash; &nbsp;
                            {moment.utc(comment.createdDatetime).format("L")}
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  ))}
                </Stack>
              ) : (
                <Box p={8}>
                  <NoRows title="No Comments" />
                </Box>
              )}
            </DialogContent>

            <DialogActions
              sx={{
                padding: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <AppBar position="static" color="transparent" elevation={0}>
                  <Toolbar>
                    <TextField
                      fullWidth
                      multiline
                      minRows={1}
                      value={newComment}
                      onChange={handleAddNewCommentChange}
                      variant="standard"
                      placeholder="Add a comment..."
                      InputProps={{ disableUnderline: true }}
                      onKeyDown={handleAddNewCommentKeyDown}
                    />
                    <IconButton
                      onClick={btnAddCommentClicked}
                      disabled={newComment.trim() === ""}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
              </Box>
            </DialogActions>
          </>
        }
        buttonText="Create"
        title="Comments"
      />
    </>
  );
});

export default Comments;
