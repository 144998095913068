import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// @ts-ignore
import SwipeableViews from "react-swipeable-views-react-18-fix";
import {
  Stack,
  Typography,
  Divider,
  Grid,
  IconButton,
  TextField,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Tabs,
  AppBar,
  ListItem,
  ListItemText,
  Toolbar,
  Tooltip,
  ListItemButton,
  FormHelperText,
} from "@mui/material";
import axiosInstance from "../../utils/axiosInstance";
import Dropdown from "../../components/Dropdown";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { AttachFile } from "@mui/icons-material";
import NoRows from "../../components/DataGrid/animations/NoRows";
import { useAccount, useMsal } from "@azure/msal-react";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSnackbarContext } from "../../providers/SnackbarProvider";
import { generatePDF } from "../../utils/generatePDF";

const theme = createTheme();

enum Status {
  NotReceivedYet = "Not received yet",
  ReceivedWithErrors = "Received with errors",
  Received = "Received",
  Closed = "Closed",
}

const statusOptions = [
  {
    value: Status.NotReceivedYet,
    text: Status.NotReceivedYet,
  },
  {
    value: Status.ReceivedWithErrors,
    text: Status.ReceivedWithErrors,
  },
  {
    value: Status.Received,
    text: Status.Received,
  },
  {
    value: Status.Closed,
    text: Status.Closed,
  },
];

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 0,
            height: 400,
            width: "100%",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function Edit() {
  const { accounts } = useMsal();
  const location = useLocation();
  const { showSnackbar } = useSnackbarContext();
  const pathParts = location.pathname.split("/"); // Split the pathname by '/'
  const id = pathParts[pathParts.length - 1];
  const fileInputRef = React.useRef(null);
  const account = useAccount(accounts[0]);
  const [PDFData, setPDFData] = useState<any>();
  const [showMaxCharWarning, setShowMaxCharWarning] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [value, setValue] = useState(0);
  const [formState, setFormState] = useState<{
    region: string;
    type: string;
    projectNumber: string;
    projectName: string;
    section: string;
    brixProjectId: string;
    guaranteeFileNumber: string;
    status: string;
    addresses: {
      id: string;
      takedownId: number;
      address: string;
      block: string;
      price: string;
      lot: string;
      jobNumber: string;
      modifiedBy: string;
    }[];
  }>({
    region: "",
    type: "",
    projectNumber: "",
    projectName: "",
    section: "",
    guaranteeFileNumber: "",
    brixProjectId: "",
    status: "",
    addresses: [],
  });

  const [addressState, setAddressState] = useState({
    address: "",
    takedownId: id,
    lot: "",
    block: "",
    price: "",
    jobNumber: "",
    modifiedBy: account?.name || "",
  });
  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = React.useState<
    {
      comment: string;
      createdBy: string;
      createdDatetime: string;
      id: number;
      isActive: boolean;
      modifiedBy: string;
      modifiedDatetime: string;
      takedownId: number;
    }[]
  >([]);
  const [attachments, setAttachments] = React.useState<
    {
      attachmentName: string;
      createdBy: string;
      createdDatetime: string;
      id: number;
      isActive: boolean;
      modifiedBy: string;
      modifiedDatetime: string;
      sasUrl: string;
      takedownId: number;
    }[]
  >([]);
  const [jobNumbers, setJobNumbers] = React.useState<
    {
      text: string;
      value: string;
    }[]
  >([]);
  const [initialValues, setInitialValues] = useState<{
    guaranteeFileNumber: string;
    status: string;
  }>({
    guaranteeFileNumber: "",
    status: "",
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const addComment = () => {
    if (!newComment.length) return;
    saveComment();
  };

  function modifyArray(array: any[]): any[] {
    array.forEach((item) => {
      if (item.hasOwnProperty("address")) {
        item.addressStreet = item["address"];
        delete item["address"];
      }
      if (item.id === "NEW_ADDRESS") {
        delete item.id; // Remove the id property
        item.createdby = account?.name || ""; // Add the createdby property
      }
    });
    return array;
  }

  const updateTakedown = async () => {
    setLoading(true);
    try {
      const updatedTakedownData = {
        id: parseInt(id),
        guaranteeFileNumber: formState.guaranteeFileNumber,
        titleStatusId: (() => {
          switch (formState.status) {
            case Status.NotReceivedYet:
              return 1;
            case Status.ReceivedWithErrors:
              return 3;
            case Status.Closed:
              return 4;
            default:
              return 2;
          }
        })(),
        modifiedBy: account?.name || "",
      };

      const takedownPromise = axiosInstance.post(
        `/api/UpdateTakedown`,
        updatedTakedownData
      );

      // Update the takedown lots
      const lotsPromise = axiosInstance.post(`/api/UpdateTakedownLots`, [
        ...modifyArray(formState.addresses),
      ]);

      const [takedownResponse, lotsResponse] = await Promise.all([
        takedownPromise,
        lotsPromise,
      ]);

      if (takedownResponse.status === 200 && lotsResponse.status === 200) {
        // Redirect to the details page or perform any other necessary actions
        showSnackbar("Updated takedown successfully", "success");
        loadDetails(id);
      } else {
        showSnackbar("There was an issue updating the takedown", "error");
      }
    } catch (error) {
      console.error("Error updating takedown:", error);
    }
    setLoading(false);
  };

  const loadDetails = (id: string) => {
    //setLoading(true);
    axiosInstance
      .get(`/api/GetTakedownDetails/${id}`)
      .then((res) => {
        setFormState({
          region: res.data.results.region,
          type: res.data.results.type,
          projectNumber: res.data.results.projectNumber,
          projectName: res.data.results.projectName,
          section: res.data.results.section,
          guaranteeFileNumber: res.data.results.guaranteeFileNumber,
          brixProjectId: res.data.results.brixProjectId,
          status: res.data.results.status,
          addresses: res.data.results.takedownLots
            .filter((lot: any) => lot.isActive)
            .map(
              (lot: {
                id: number;
                takedownId: number;
                addressStreet: any;
                lot: any;
                block: any;
                price: any;
                modifiedBy: string;
                jobNumber: any;
              }) => ({
                id: lot.id,
                takedownId: lot.takedownId,
                address: lot.addressStreet,
                lot: lot.lot,
                block: lot.block,
                jobNumber: lot.jobNumber,
                price: lot.price,
                modifiedBy: account?.name || "",
              })
            ),
        });
        setInitialValues({
          guaranteeFileNumber: res.data.results.guaranteeFileNumber,
          status: res.data.results.status,
        });
        setAttachments(
          res.data.results.takedownAttachments.filter(
            (c: { isActive: boolean }) => c.isActive === true
          )
        );
        setComments(
          res.data.results.takedownComments.filter(
            (c: { isActive: boolean }) => c.isActive === true
          )
        );
        setPDFData(res.data.results);
      })

      .catch((err) => {
        console.error(err);
      });
  };

  const handleFileChange = async (event: {
    target: { files: any[]; value: any };
  }) => {
    const file = event.target.files[0];
    const maxSizeInBytes = 20 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      showSnackbar("File exceeds maximum size of 20mb", "error");
      return;
    }
    showSnackbar("Uploading Attachment", "success");
    if (file) {
      setValue(1);
      // Handle the file, e.g., upload to a server or display a preview
      // get the url to attach
      axiosInstance.get(`/api/GetURLtoAttach/${file.name}`).then((res) => {
        if (res.status === 200) {
          const { successMsg: urlToAttach } = res.data;
          var xhr = new XMLHttpRequest();
          xhr.open("PUT", urlToAttach, true);
          xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
          xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
              axiosInstance
                .post("/api/CreateAttachment", {
                  attachmentName: file.name,
                  takedownId: parseInt(id),
                  createdBy: account?.name || "",
                  modifiedBy: account?.name || "",
                  isActive: true,
                  sasUrl: urlToAttach,
                })
                .then((res) => {
                  if (res.status === 200) {
                    showSnackbar("Attachment created successfully", "success");
                  } else {
                    showSnackbar(
                      "There was an error creating the attachment",
                      "error"
                    );
                  }
                })
                .catch((err) => {
                  console.error(
                    `There was an unexpected error creating the attachment: ${err}`
                  );
                })
                .finally(() => {
                  setNewComment("");
                  loadDetails(id);
                });
            } else {
              console.log(`Upload failed: ${xhr.responseText}`);
            }
          };
          xhr.onerror = function () {
            console.log("There was an error uploading the file");
          };
          xhr.send(file as Blob);
        } else {
          console.log("There was an error generating the SAS URL");
        }
      });
    }
  };

  const handlePrint = async () => {
    if (!PDFData) return;
    generatePDF(PDFData);
  };

  const saveComment = () => {
    axiosInstance
      .post(`/api/CreateTakeDownComment`, {
        Comment: newComment.trim(),
        TakedownId: parseInt(id),
        CreatedBy: account?.name || "",
        ModifiedBy: account?.name || "",
        CreatedDateTime: new Date(),
        ModifiedDateTime: new Date(),
        isActive: true,
      })
      .then((res) => {
        if (res.status === 200 && (res?.data?.results ?? -1) > 0) {
          setValue(0);
          setNewComment("");
          loadDetails(id);
        } else {
          showSnackbar("There was an error creating a comment", "error");
        }
      });
  };

  const deleteLot = async (lotId: string) => {
    if (formState.addresses.length > 1)
      axiosInstance
        .post(`/api/DeleteTakedownLot/`, {
          id: lotId,
          modifiedBy: account?.name || "",
        })
        .then((res) => {
          loadDetails(id);
        })
        .catch((err) => {
          console.error(err);
        });
  };

  const loadJobNumbers = async () => {
    if (!formState.projectNumber && !formState.section) return;
    axiosInstance
      .get(
        `/api/GetJobNumbersbyProjectSection/${formState.projectNumber}/${formState.section}`
      )
      .then((res) => {
        setJobNumbers(
          res.data.results.map((job: string) => {
            return {
              text: job,
              value: job,
            };
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (!formState.section || !formState.projectNumber) return;
    loadJobNumbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.projectNumber, formState.section]);

  useEffect(() => {
    if (id) {
      loadDetails(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const sortedAttachments = [...attachments].sort((a, b) => {
    // Convert the createdDatetime strings to Date objects for comparison
    const dateA = new Date(a.createdDatetime);
    const dateB = new Date(b.createdDatetime);
    //@ts-ignore
    return dateB - dateA; // This will sort the array in descending order
  });

  const sortedComments = [...comments].sort((a, b) => {
    // Convert the createdDatetime strings to Date objects for comparison
    const dateA = new Date(a.createdDatetime);
    const dateB = new Date(b.createdDatetime);

    //@ts-ignore
    return dateB - dateA; // This will sort the array in descending order
  });

  const isGuaranteeFileNumberDirty =
    formState.guaranteeFileNumber !== initialValues.guaranteeFileNumber;
  const isStatusDirty = formState.status !== initialValues.status;

  const isFormDirty = isGuaranteeFileNumberDirty || isStatusDirty;

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="lg"
        sx={{ mb: 8, mt: 4, maxWidth: "1080px !important" }}
      >
        <CssBaseline />
        <Box
          width={"100%"}
          sx={{
            mt: (theme) => theme.spacing(1),
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            width={"100%"}
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
              py: (theme) => theme.spacing(2),
              px: (theme) => theme.spacing(2),
            }}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "IBM Plex Sans Arabic",
                  fontWeight: 500,
                }}
              >
                Edit Lot Take Down{" "}
                {formState.projectName && ` - ${id} ${formState.projectName}`}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
              sx={{
                flex: 1,
                direction: "row",
                mt: { sm: 0, md: 0 },
              }}
            >
              <LoadingButton
                disabled={!PDFData}
                loading={loading}
                variant="contained"
                onClick={handlePrint}
              >
                Print
              </LoadingButton>
              <LoadingButton
                disabled={!isFormDirty}
                loading={loading}
                variant="contained"
                onClick={updateTakedown}
              >
                Update
              </LoadingButton>
            </Stack>
          </Box>
          <Box width={"100%"} paddingX={3}>
            <Box paddingY={(theme) => theme.spacing(2)} paddingX={1}>
              <Stack direction={"row"} spacing={2} mt={3}>
                <Dropdown
                  options={[
                    {
                      value: "DFW",
                      text: "DFW",
                    },
                    {
                      value: "Austin",
                      text: "Austin",
                    },
                    {
                      value: "Houston",
                      text: "Houston",
                    },
                    {
                      value: "San Antonio",
                      text: "San Antonio",
                    },
                  ]}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      [e.target.name]: e.target.value,
                    })
                  }
                  id="region"
                  label={"Region"}
                  name={`region`}
                  disabled
                  variant="outlined"
                  value={formState.region}
                />
                <Dropdown
                  options={[
                    {
                      value: "Cash",
                      text: "Cash",
                    },
                    {
                      value: "Interim",
                      text: "Interim",
                    },
                  ]}
                  disabled
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      [e.target.name]: e.target.value,
                    })
                  }
                  id="type"
                  label="Type"
                  name="type"
                  variant="outlined"
                  value={formState.type}
                  InputLabelProps={{ shrink: true }}
                />
              </Stack>
              <Stack direction={"row"} spacing={2} mt={3}>
                <TextField
                  fullWidth
                  disabled
                  id="projectName"
                  label="Project Name"
                  name="projectName"
                  variant="outlined"
                  value={formState.projectName}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      projectName: e.target.value,
                    });
                  }}
                />
                <TextField
                  fullWidth
                  disabled
                  id="projectNumber"
                  label="Project Number"
                  name="projectNumber"
                  variant="outlined"
                  value={formState.projectNumber}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      projectNumber: e.target.value,
                    });
                  }}
                />
                <TextField
                  fullWidth
                  id="section"
                  label="Section"
                  name="section"
                  variant="outlined"
                  disabled
                  value={formState.section}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      section: e.target.value,
                    });
                  }}
                />
              </Stack>
              <Stack direction={"row"} spacing={2} mt={3}>
                <TextField
                  fullWidth
                  id="brixProjectId"
                  label="Brix Project ID"
                  name="brixProjectId"
                  variant="outlined"
                  disabled
                  value={formState.brixProjectId}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    setFormState({
                      ...formState,
                      brixProjectId: e.target.value,
                    });
                  }}
                />
                <TextField
                  fullWidth
                  id="guaranteeFileNumber"
                  label="GF Number"
                  name="guaranteeFileNumber"
                  variant="outlined"
                  value={formState.guaranteeFileNumber}
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => {
                    // Regular expression to allow only non-special characters
                    const regex = /^[a-zA-Z0-9]*$/;

                    // Check if the current value matches the regular expression
                    if (regex.test(e.target.value)) {
                      setFormState({
                        ...formState,
                        guaranteeFileNumber: e.target.value.toUpperCase(),
                      });
                    }
                  }}
                />

                <Dropdown
                  options={statusOptions}
                  onChange={(e) =>
                    setFormState({
                      ...formState,
                      status: e.target.value,
                    })
                  }
                  id="status"
                  label="Status"
                  name="status"
                  variant="outlined"
                  value={formState.status}
                />
              </Stack>
            </Box>
            <Box paddingY={(theme) => theme.spacing(1)} paddingX={1}>
              <Accordion
                defaultExpanded
                square
                elevation={0}
                sx={{
                  mt: 2,
                  border: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>
                    Addresses {`(${formState.addresses.length})`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack direction="column" spacing={0} mt={3}>
                    {formState.addresses.map((address, index) => (
                      <Grid container spacing={1} mt={2} key={index}>
                        <Grid item xs={5}>
                          <TextField
                            fullWidth
                            disabled
                            id="address"
                            label="Address"
                            name="address"
                            variant="outlined"
                            value={address.address}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            disabled
                            id="jobNumber"
                            label="Job Number"
                            name="jobNumber"
                            variant="outlined"
                            value={address.jobNumber}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextField
                            fullWidth
                            disabled
                            type="text"
                            id="lot"
                            label="Lot"
                            name="lot"
                            variant="outlined"
                            value={address.lot}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              // Regex to allow only letters and numbers
                              if (/^[a-zA-Z0-9]*$/.test(newValue)) {
                                address.lot = newValue;
                              }
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <TextField
                            fullWidth
                            disabled
                            id="block"
                            label="Block"
                            type="text"
                            name="block"
                            variant="outlined"
                            value={address.block}
                            onChange={(e) => {
                              const newValue = e.target.value;
                              // Regex to allow only letters and numbers
                              if (/^[a-zA-Z0-9]*$/.test(newValue)) {
                                address.block = newValue;
                              }
                            }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                            fullWidth
                            disabled
                            id="price"
                            label="Price"
                            name="price"
                            variant="outlined"
                            value={address.price}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={1}
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Tooltip title={"Remove Address"}>
                            <IconButton
                              onClick={() => {
                                if (formState.addresses.length > 1) {
                                  deleteLot(address.id);
                                  setFormState({
                                    ...formState,
                                    addresses: [
                                      ...formState.addresses.slice(0, index),
                                      ...formState.addresses.slice(index + 1),
                                    ],
                                  });
                                } else {
                                  showSnackbar(
                                    "There must be at least one address in a takedown request. Please add one before deleting.",
                                    "error"
                                  );
                                }
                              }}
                            >
                              <RemoveCircleOutlineIcon />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    ))}
                  </Stack>
                </AccordionDetails>
              </Accordion>
              <Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  align="left"
                  sx={{
                    mb: 2,
                  }}
                >
                  Add New Address
                </Typography>
              </Box>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={5}>
                    <TextField
                      fullWidth
                      id="address"
                      label="Address"
                      name="address"
                      variant="outlined"
                      value={addressState.address}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // Check if the length exceeds 5 characters
                        if (newValue.length > 50) {
                          // Show warning and prevent updating the state
                          setShowMaxCharWarning(true);
                        } else {
                          // Hide warning and update the state
                          setShowMaxCharWarning(false);
                          setAddressState({
                            ...addressState,
                            address: e.target.value,
                          });
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Dropdown
                      options={jobNumbers}
                      id="jobNumber"
                      label="Job Number"
                      name="jobNumber"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={addressState.jobNumber}
                      onChange={(e) =>
                        setAddressState({
                          ...addressState,
                          jobNumber: e.target.value,
                        })
                      }
                      noOptionsText={
                        !formState.section.length
                          ? "No section or project number available"
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      id="lot"
                      label="Lot"
                      name="lot"
                      variant="outlined"
                      value={addressState.lot}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // Check if the length exceeds 5 characters
                        if (newValue.length > 5) {
                          // Show warning and prevent updating the state
                          setShowMaxCharWarning(true);
                        } else {
                          // Hide warning and update the state
                          setShowMaxCharWarning(false);
                          if (/^[a-zA-Z0-9]*$/.test(newValue)) {
                            setAddressState({
                              ...addressState,
                              lot: newValue,
                            });
                          }
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <TextField
                      fullWidth
                      id="block"
                      label="Block"
                      name="block"
                      variant="outlined"
                      value={addressState.block}
                      onChange={(e) => {
                        const newValue = e.target.value;
                        // Check if the length exceeds 5 characters
                        if (newValue.length > 5) {
                          // Show warning and prevent updating the state
                          setShowMaxCharWarning(true);
                        } else {
                          // Hide warning and update the state
                          setShowMaxCharWarning(false);
                          if (/^[a-zA-Z0-9]*$/.test(newValue)) {
                            setAddressState({
                              ...addressState,
                              block: newValue,
                            });
                          }
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      fullWidth
                      id="price"
                      label="Price"
                      name="price"
                      variant="outlined"
                      value={addressState.price}
                      onChange={(e) => {
                        let newValue = e.target.value;
                        if (newValue.length > 10) {
                          // Show warning and prevent updating the state
                          setShowMaxCharWarning(true);
                        } else {
                          setShowMaxCharWarning(false);
                          // Regular expression to allow only positive numbers with up to two decimal places
                          const decimalRegex = /^\d*(\.\d{0,2})?$/;

                          // If the value starts with a decimal, prepend '0'
                          if (newValue.startsWith(".")) {
                            newValue = "0" + newValue;
                          }

                          // Check if the new value is an empty string or matches the decimal regex
                          if (newValue === "" || decimalRegex.test(newValue)) {
                            setAddressState({
                              ...addressState,
                              price: newValue,
                            });
                          }
                        }
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Tooltip title={"Add Address"}>
                      <IconButton
                        onClick={async () => {
                          // Update the takedown lots
                          const lotsPromise = await axiosInstance.post(
                            `/api/UpdateTakedownLots`,
                            [
                              ...modifyArray([
                                ...formState.addresses,
                                {
                                  id: "NEW_ADDRESS",
                                  takedownId: parseInt(id),
                                  address: addressState.address.trim(),
                                  lot: addressState.lot.trim(),
                                  block: addressState.block.trim(),
                                  price: addressState.price.trim(),
                                  modifiedBy: account?.name || "",
                                  jobNumber: addressState.jobNumber,
                                },
                              ]),
                            ]
                          );
                          if (lotsPromise.status === 200) {
                            showSnackbar(
                              "Takedown lot added successfully",
                              "success"
                            );
                            loadDetails(id);
                          }
                          setShowMaxCharWarning(false);
                          setAddressState({
                            ...addressState,
                            address: "",
                            lot: "",
                            block: "",
                            price: "",
                            jobNumber: "",
                          });
                        }}
                        disabled={
                          !addressState.address.trim() ||
                          !addressState.lot.trim() ||
                          !addressState.price.trim() ||
                          !addressState.jobNumber.trim()
                        }
                      >
                        <AddCircleOutlineIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Box px={4}>
                    <FormHelperText error={true}>
                      {showMaxCharWarning &&
                        "Address must be 50 Characters or less. Lot and Block must be 5 characters or less. Price must be 10 characters or less."}
                    </FormHelperText>
                  </Box>
                </Grid>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "100%",
              paddingX: 4,
            }}
          >
            <Box
              sx={{
                bgcolor: "background.paper",
                width: "100%",
                ".swipeableViews::-webkit-scrollbar": {
                  display: "none",
                },
                mt: 1,
              }}
            >
              <AppBar position="static">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="secondary"
                  textColor="inherit"
                  variant="fullWidth"
                >
                  <Tab label="Comments" {...a11yProps(0)} />
                  <Tab label="Attachments" {...a11yProps(1)} />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                className="swipeableViews"
                onChangeIndex={handleChangeIndex}
                style={{
                  height: "400px",
                  width: "100%",
                  display: "block",
                  borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <Box
                    sx={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Stack
                      divider={<Divider />}
                      direction="column"
                      spacing={0}
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                      }}
                    >
                      {sortedComments.length ? (
                        sortedComments.map((comment) => (
                          <ListItem
                            key={comment.id}
                            alignItems="flex-start"
                            sx={{
                              width: "100%",
                              wordWrap: "break-word",
                            }}
                          >
                            <ListItemText
                              primary={comment.comment}
                              sx={{ wordWrap: "break-word" }}
                              secondary={
                                <React.Fragment>
                                  {comment.createdBy || ""} &nbsp; &mdash;
                                  &nbsp;
                                  {moment
                                    .utc(comment.createdDatetime)
                                    .format("L")}
                                </React.Fragment>
                              }
                            />
                          </ListItem>
                        ))
                      ) : (
                        <Box p={8}>
                          <NoRows title="No Comments" />
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <Box
                    sx={{
                      display: "block",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <Stack
                      divider={<Divider />}
                      direction="column"
                      spacing={0}
                      sx={{
                        width: "100%",
                        bgcolor: "background.paper",
                      }}
                    >
                      {sortedAttachments.length ? (
                        sortedAttachments.map((attachment) => (
                          <ListItemButton
                            key={attachment.id}
                            alignItems="flex-start"
                            sx={{
                              width: "100%",
                              wordWrap: "break-word",
                            }}
                            href={attachment.sasUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <ListItemText
                              primary={attachment.attachmentName}
                              sx={{
                                wordWrap: "break-word",
                              }}
                              secondary={
                                <React.Fragment>
                                  {attachment?.createdBy || ""} &nbsp; &mdash;
                                  &nbsp;
                                  {moment
                                    .utc(attachment?.createdDatetime)
                                    .format("L")}
                                </React.Fragment>
                              }
                            />
                          </ListItemButton>
                        ))
                      ) : (
                        <Box p={8}>
                          <NoRows title="No Attachments" />
                        </Box>
                      )}
                    </Stack>
                  </Box>
                </TabPanel>
              </SwipeableViews>
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  borderLeft: "1px solid rgba(0, 0, 0, 0.1)",
                  borderRight: "1px solid rgba(0, 0, 0, 0.1)",
                  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <AppBar position="static" color="transparent" elevation={0}>
                  <Toolbar
                    sx={{
                      minHeight: "45px !important",
                    }}
                  >
                    <div>
                      <input
                        style={{ display: "none" }}
                        id="upload-file"
                        type="file"
                        accept=".txt, .pdf, .png, .jpg, text/plain, application/pdf, image/png, image/jpeg"
                        ref={fileInputRef}
                        // @ts-ignore
                        onChange={handleFileChange}
                      />

                      <label htmlFor="upload-file">
                        <Tooltip title="Upload Attachment">
                          <IconButton
                            aria-label="upload picture"
                            component="span"
                            // onClick={handleUploadClick}
                          >
                            <AttachFile />
                          </IconButton>
                        </Tooltip>
                      </label>
                    </div>
                    <TextField
                      fullWidth
                      multiline
                      minRows={1}
                      value={newComment}
                      onChange={(e) => setNewComment(e.target.value)}
                      variant="standard"
                      placeholder="Add a comment..."
                      InputProps={{ disableUnderline: true }}
                      onKeyDown={(e) => {
                        if (!newComment.trim().length) return;
                        if (e.key === "Enter") {
                          addComment();
                        }
                      }}
                    />
                    <IconButton
                      slot="end"
                      size="small"
                      onClick={addComment}
                      disabled={!newComment.trim()}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Toolbar>
                </AppBar>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
