import React from "react";
import { Formik } from "formik";

type Props = {
  children: any;
  onSubmitHandle?: any;
  validationSchema?: any;
  initialValues: any;
  validateOnChange?: boolean;
  validateOnBlur?: boolean;
  validateOnMount?: boolean;
  enableReinitialize?: boolean;
};

const Form = ({
  children,
  onSubmitHandle,
  validationSchema,
  initialValues,
  validateOnMount,
  validateOnChange,
  validateOnBlur,
  enableReinitialize,
}: Props) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={enableReinitialize}
      onSubmit={(values, formikHelpers) => {
        if (typeof onSubmitHandle === "function") {
          onSubmitHandle(values, formikHelpers);
        }
      }}
      validateOnMount={validateOnMount}
      validateOnChange={validateOnChange}
      validateOnBlur={validateOnBlur}
    >
      {(formikProps) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formikProps.handleSubmit(e);
          }}
        >
          {children(formikProps)}
        </form>
      )}
    </Formik>
  );
};

export default Form;
