import React, { useState, useEffect } from "react";
import ReusableDialog from "../../components/Dialog";
import {
  Box,
  DialogActions,
  DialogContent,
  Divider,
  FormHelperText,
  Grid,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import AttachmentIcon from "@mui/icons-material/AttachFile";
import axiosInstance from "../../utils/axiosInstance";
import { useAccount, useMsal } from "@azure/msal-react";
import moment from "moment";
import NoRows from "../../components/DataGrid/animations/NoRows";
import { FileUploader } from "react-drag-drop-files";
import { useSnackbarContext } from "../../providers/SnackbarProvider";

type Props = {
  takeDownID: string;
};

const Attachments = (props: Props) => {
  const [open, setOpen] = useState(false);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0]);
  const { showSnackbar } = useSnackbarContext();

  const [attachments, setAttachments] = React.useState<
    {
      id: number;
      attachmentName: string;
      sasUrl: string;
      takedownId: number;
      isActive: boolean;
      createdDatetime: string;
      createdBy: string;
      modifiedDatetime: string;
      modifiedBy: string;
    }[]
  >([]);

  const sortedAttachments = [...attachments].sort((a, b) => {
    // Convert the createdDatetime strings to Date objects for comparison
    const dateA = new Date(a.createdDatetime);
    const dateB = new Date(b.createdDatetime);
    //@ts-ignore
    return dateB - dateA; // This will sort the array in descending order
  });

  const handleUpload = (fileList: FileList | null) => {
    const maxSizeInBytes = 20 * 1024 * 1024;
    if (fileList?.length) {
      if (fileList[0].size > maxSizeInBytes) {
        showSnackbar("File exceeds maximum size of 20mb", "error");
        return;
      }
      showSnackbar("Uploading Attachment", "success");
      const fileListArray = Array.from(fileList);
      const uploadPromises = fileListArray.map(
        (attachment, attachmentIndex) => {
          return axiosInstance
            .get(`/api/GetURLtoAttach/${attachment.name}`)
            .then((res) => {
              if (res.status === 200) {
                const { successMsg: urlToAttach } = res.data;
                return new Promise<void>((resolve, reject) => {
                  const xhr = new XMLHttpRequest();
                  xhr.open("PUT", urlToAttach, true);
                  xhr.setRequestHeader("x-ms-blob-type", "BlockBlob");
                  xhr.onload = function () {
                    if (xhr.status >= 200 && xhr.status < 300) {
                      axiosInstance
                        .post("/api/CreateAttachment", {
                          attachmentName: attachment.name,
                          takedownId: parseInt(props.takeDownID),
                          createdBy: account?.name || "",
                          modifiedBy: account?.name || "",
                          isActive: true,
                          sasUrl: urlToAttach,
                        })
                        .then((res) => {
                          if (res.status === 200) {
                            resolve();
                          } else {
                            reject(
                              `There was an error creating the attachment`
                            );
                          }
                        })
                        .catch((err) => {
                          reject(
                            `There was an error creating the attachment: ${err}`
                          );
                        });
                    } else {
                      reject(`Upload failed: ${xhr.responseText}`);
                    }
                  };
                  xhr.send(fileList[attachmentIndex] as Blob);
                });
              } else {
                return Promise.reject("There was an error creating the url");
              }
            });
        }
      );

      try {
        Promise.all(uploadPromises).then(() => {
          loadAttachments(props.takeDownID);
          showSnackbar("All attachments uploaded successfully", "success");
        });
      } catch (error) {
        console.error(`Error uploading attachments: ${error}`);
        showSnackbar("Error uploading attachments", "error");
      }
    }
  };

  const loadAttachments = (id: string) => {
    axiosInstance
      .get(`/api/GetTakeDownAttachments/${id}`)
      .then((res) => {
        setAttachments(
          res.data.results.filter(
            (c: { isActive: boolean }) => c.isActive === true
          )
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (open && props.takeDownID) {
      // Load comments only when the dialog opens and a takedown ID is present
      loadAttachments(props.takeDownID);
    }
  }, [open, props.takeDownID]);

  return (
    <>
      <ReusableDialog
        type="icon"
        icon={<AttachmentIcon />}
        setIsOpen={setOpen}
        setIsClosed={() => {
          setOpen(false);
          setAttachments([]);
        }}
        isOpen={open}
        maxWidth={"md"}
        content={
          <>
            <DialogContent
              sx={{
                padding: 0,
                height: "60vh", // Set the maximum height for the modal
                overflow: "hidden", // Prevent scrolling within the entire dialog content
              }}
            >
              <Grid container spacing={0} sx={{ height: "100%" }}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  p={3}
                  sx={{
                    ".drop_area": {
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      minWidth: "unset",
                      maxHeight: "340px",
                      marginTop: "40px",
                      backgroundColor: "aliceblue",
                      ".sc-fFGjHI": {
                        fontSize: "1rem",
                        textAlign: "center",
                        marginTop: "1rem",
                      },
                      svg: {
                        width: "800px",
                        display: "flex",
                      },
                      div: {
                        flexGrow: "unset !important",
                      },
                    },
                    overflow: "hidden", // Prevent scrolling on the left side
                    maxHeight: "100%", // Make sure this part doesn't grow beyond the modal's height
                  }}
                >
                  {/* File uploader component */}
                  <FileUploader
                    multiple={true}
                    handleChange={handleUpload}
                    label="Click here or drag and drop your file to upload. There is a 20mb max file size limit."
                    name="file"
                    height="100%"
                    width="100%"
                    classes="drop_area drop_zone"
                    types={[
                      "txt",
                      "pdf",
                      "png",
                      "jpg",
                      "image/jpeg",
                      "image/png",
                      "application/pdf",
                      "text/plain",
                    ]}
                  />
                  <FormHelperText
                    sx={{
                      mt: 1,
                      textAlign: "center",
                      fontSize: "0.8rem",
                    }}
                  >
                    Use the drop area above to upload your files. You will be
                    able to view uploads on the right hand side.
                  </FormHelperText>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    maxHeight: "100%", // Ensure the Grid item has a max height
                    overflow: "hidden", // Hide overflow here to prevent the entire grid from scrolling
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      border: "1px solid rgba(0,0,0,0.12)",
                    }}
                  >
                    {sortedAttachments.length ? (
                      <Stack
                        divider={<Divider />}
                        direction="column"
                        spacing={0}
                        sx={{
                          overflow: "scroll", // Enable scrolling inside the stack
                          maxHeight: "100%", // Set a max height for the stack
                        }}
                      >
                        {/* List of attachments */}
                        {sortedAttachments.map((attachment) => (
                          <ListItemButton
                            href={attachment.sasUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={attachment.id}
                            alignItems="flex-start"
                            sx={{
                              wordWrap: "break-word",
                            }}
                          >
                            <ListItemText
                              primary={attachment.attachmentName}
                              secondary={
                                <React.Fragment>
                                  {attachment.createdBy} &nbsp; &mdash; &nbsp;
                                  {moment
                                    .utc(attachment.createdDatetime)
                                    .format("L")}
                                </React.Fragment>
                              }
                            />
                          </ListItemButton>
                        ))}
                      </Stack>
                    ) : (
                      <Box p={8}>
                        <NoRows title="No attachments" />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions
              sx={{
                padding: 0,
              }}
            ></DialogActions>
          </>
        }
        buttonText="Create"
        title="Attachments"
      />
    </>
  );
};

export default Attachments;
