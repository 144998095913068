import React from "react";
import Header from "../Header";
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "../Buttons/SignInButton";
import { SignOutButton } from "../Buttons/SignOutButton";

const Layout = (props: { children: any }) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      <Header
        headerRight={isAuthenticated ? <SignOutButton /> : <SignInButton />}
      />
      {props.children}
    </>
  );
};

export default Layout;
