// SnackbarContext.tsx
import React, { createContext, useState, useContext, ReactNode } from "react";

type Severity = "error" | "success" | "info" | "warning";

interface SnackbarContextType {
  open: boolean;
  message: string;
  severity: Severity;
  showSnackbar: (msg: string, severity: Severity) => void;
  closeSnackbar: () => void;
}

const SnackbarContext = createContext<SnackbarContextType>(
  {} as SnackbarContextType
);

const ERROR_MESSAGE = "useSnackbarContext must be used within a SnackbarProvider";

export const useSnackbarContext = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (!context) {
    //Returning a default context prevents the code from throwing an error and allows the application to continue running
    return {
      open: false,
      message: ERROR_MESSAGE,
      severity: "error",
      showSnackbar: (msg: string, severity: Severity) => {},
      closeSnackbar: () => {},
    };
  }
  return context;
};

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({
  children,
}) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<Severity>("error"); // Set default severity to error

  const showSnackbar = (msg: string, severity: Severity) => {
    setMessage(msg);
    setSeverity(severity);
    setOpen(true);
  };

  const closeSnackbar = () => {
    setOpen(false);
  };

  return (
    <SnackbarContext.Provider
      value={{ open, message, severity, showSnackbar, closeSnackbar }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};
