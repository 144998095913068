import { FormControl, MenuItem, TextField } from "@mui/material";
import React from "react";

type Props = {
  label: string;
  error?: boolean;
  noOptionsText?: string;
  name: string;
  InputLabelProps?: any;
  disabled?: boolean;
  helperText?: string;
  value?: string | [{ value: string; text: string }] | undefined;
  id?: string;
  variant: "standard" | "outlined" | "filled" | undefined;
  options: {
    value: string;
    text: string;
  }[];
  size?: "small" | "normal";

  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
};

const Dropdown = ({
  options,
  onChange,
  label,
  value,
  id,
  variant,
  disabled = false,
  InputLabelProps,
  name,
  helperText,
  error,
  noOptionsText,
  size = "normal",
}: Props) => {
  return (
    <FormControl margin="none" fullWidth>
      <TextField
        // sx={{ "& legend": { display: "none" }, "& fieldset": { top: 0 } }}
        variant={variant}
        name={name}
        id={id}
        select
        label={label}
        value={value}
        disabled={disabled}
        // InputLabelProps={InputLabelProps}
        onChange={onChange}
        error={error}
        helperText={helperText}
        size={size as any}
        InputLabelProps={{ shrink: true }}
      >
        {!options.length && (
          <MenuItem disabled value="">
            {noOptionsText}
          </MenuItem>
        )}
        {options.map((obj, i) => (
          <MenuItem value={obj.value} key={i}>
            {obj.text}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
};

export default Dropdown;
